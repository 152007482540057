// Font family
$font-stack: 'Roboto', sans-serif;

html {
  color: $bk-a;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-stack !important;
  transition: opacity .1s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

.list-bullet {
  font-size: 15px;
  li {
    position: relative;
    padding-left: 20px;
    &:not(:first-child) {
      margin-top: 5px;
    }
    &:before {
      content: '';
      width: 7px;
      height: 7px;
      background-color: $rd-b;
      position: absolute;
      top: 5px;
      left: 0;
      border-radius: 100%;
    }
  }
}
p {
  font-size: 15px;
  line-height: 1.5em;
  margin-bottom: 0;
  &.bold {
    font-weight: 500;
  }
}
// Title hierarchy :: Bigger to smaller

.h-like-a,
.h-like-b,
.h-like-c {
  font-weight: 400;
  line-height: 1.3em;
  font-style: italic;
  strong {
    font-weight: 600;
    font-style: normal;
  }

}

.h-like-a {
  font-size: 33px;
}
.h-like-b {
  font-size: 30px;
}
.h-like-c {
  font-size: 24px;
}
.h-like-d {
  font-weight: 500;
  font-size: 22px;
}
.h-like-e {
  font-weight: 500;
  line-height: 1.2em;
  font-size: 18px;
}

@include media-breakpoint-down(lg) {
  .h-like-a {}
}
@include media-breakpoint-down(md) {
  .h-like-a {}
}
@include media-breakpoint-down(sm) {
  .h-like-a {
    font-size: 30px;
  }
  .h-like-b {
    font-size: 27px;
  }
  .h-like-c {
    font-size: 21px;
  }
}
@include media-breakpoint-down(xs) {
  .h-like-a {
    font-size: 27px;
  }
  .h-like-b {
    font-size: 24px;
  }
  .h-like-c {
    font-size: 20px;
  }
}
// Paragraph hierarchy :: Bigger to smaller

.p-like-a { font-size: 16px;}
@include media-breakpoint-down(lg) {
  .p-like-a {}
}
@include media-breakpoint-down(md) {
  .p-like-a {}
}
@include media-breakpoint-down(sm) {
  .p-like-a {
    font-size: 14px;
  }
}
@include media-breakpoint-down(xs) {
  .p-like-a {}
}

.quote {
  font-size: 24px;
  font-style: italic;
  font-weight: 500;
  line-height: 1.2em;
  text-align: center;
  span {
    font-size: 40px;
    vertical-align: middle;
    padding: 0 10px;
    top: -4px;
    position: relative;
  }
}
