// Footer

#footer {
	background-color: $bk-a;
	color: $wh-a;
	padding: 25px 0;
	.txt {
		color: $wh-a;
		white-space: nowrap;
		font-size: 12px;
	}
	.menu {
		li {
			display: inline-block;
            &:after {
                content: "|";
            }
			&:last-child {
				&:after {
					content: "";
				}
			}
		}
	}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {
		padding: 20px 0;
	}
	@include media-breakpoint-down(sm) {
		padding: 15px 0;
	}
}
