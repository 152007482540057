// Sprite :: Structure

.sprite {
	display: inline-block;
	background-image: url('../images/icons/sprite.png');
	background-repeat: no-repeat;
	vertical-align: text-top;
	background-size: 1100px;
}

.sprite.back-top {
	width: 50px;
	height: 50px;
}

.sprite.back-top {
	background-position: 0 0;
}

.sprite.icon-1,
.sprite.icon-2,
.sprite.icon-3,
.sprite.icon-4,
.sprite.icon-5 {
	height: 60px;
	width: 60px;
}

.sprite.icon-1 {
	background-position: -50px 0;
}
.sprite.icon-2 {
	background-position: -110px 0;
}
.sprite.icon-3 {
	background-position: -170px 0;
}
.sprite.icon-4 {
	background-position: -230px 0;
}
.sprite.icon-5 {
	background-position: -290px 0;
}

.sprite.arrow-left,
.sprite.arrow-right {
	width: 20px;
	height: 30px;
}

.sprite.arrow-left {
	background-position: -360px 0;
}
.sprite.arrow-right {
	background-position: -380px 0;
}

.sprite.deco-1,
.sprite.deco-2,
.sprite.deco-3 {
	width: 110px;
	height: 120px;
}

.sprite.deco-1 {
	background-position: -410px 0;
}
.sprite.deco-2 {
	background-position: -530px 0;
}
.sprite.deco-3 {
	background-position: -650px 0;
}
