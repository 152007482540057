.wysiwyg-wp {
    > *:first-child {
        margin-top: 0 !important;
    }

    > *:last-child {
        margin-bottom: 0 !important;
    }

    h2 {
        font-size: 30px;
        font-weight: 700;
        color: $rd-b;
    }

    h3 {
        font-size: 26px;
        font-weight: 700;
        color: $rd-b;
    }

    .img-inline {
        margin: 25px 10px;
        display: inline-block;
    }

    .headline {
        font-size: 17px;
        font-weight: 600;
    }

    p {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .highlight-wp {
        margin-bottom: 0;
        background-color: $gy-a;
        color: $gy-b;
        font-size: 16px;
        font-weight: 600;
        font-style: italic;
        padding: 30px;
        margin: 50px auto;
        width: 75%;
        border-radius: 20px;
        display: block;
    }

    ol,
    ul {
        padding-left: 30px;
    }

    ol li,
    ul li {
        display: block;
        position: relative;
        padding-left: 22px;
        margin-bottom: 10px;
    }

    ul li {
        font-weight: 400;
        font-size: 16px;
    }

    ul li:before {
        content: "";
        @include position(absolute, 9px null null 3px);
        width: 6px;
        height: 6px;
        background-color: $rd-b;
        border-radius: 100%;
    }

    ol {
        counter-reset: ol-counter;
    }

    ol li {
        position: relative;
        display: block;
        line-height: 1.4em;
        font-size: 16px;
        font-weight: 400;
    }

    ol li:before {
        content: counter(ol-counter)".";
        color: $rd-b;
        counter-increment: ol-counter;
        font-size: px(14);
        font-weight: 600;
        position: absolute;
        top: 0;
        left: 0;
    }

    ol li:last-child {
        margin-bottom: 0;
    }

    h2,
    h3,
    ol,
    ul {
        margin: 30px 0 35px;
    }

    a {
        color: $rd-b;
        text-decoration: underline;
    }

    img {
        max-width: 100%;
        height: auto;
        border-radius: 10px;
    }

    .with-radius {
        border-radius: 50px 0 50px 0;
    }

    img.img-left {
        float: left;
        margin: 5px 30px 15px 0;
    }

    img.img-right {
        float: right;
        margin: 5px 0 15px 30px;
    }

    img.img-center {
        margin: 50px auto;
        display: block;
    }

    table {
        @extend .table;
        @extend .table-striped;
        @extend .table-bordered;
    }
    @include media-breakpoint-down(sm) {
        h2 {
            font-size: 25px;
        }

        h3 {
            font-size: 21px;
        }

        .headline {
            font-size: 16px;
        }

        .highlight-wp {
            width: 100%;
        }

        img.img-left,
        img.img-right {
            float: none;
            margin: 30px 0 !important;
            display: block;
        }

        .btn-wp a {
            padding-right: px(15);
        }
    }
    @include media-breakpoint-down(xs) {
        h2 {
            font-size: 22px;
        }

        h3 {
            font-size: 19px;
        }

        .headline {
            font-size: 15px;
        }

        li,
        ol li:before,
        p {
            font-size: 14px !important;
        }

        .highlight-wp {
            width: 100%;
        }

        ol,
        ul {
            padding-left: 0;
        }

        img,
        img.img-center {
            float: none;
            margin: 20px 0 !important;
            display: block;
        }

        h2,
        h3,
        ol,
        ul {
            margin: 20px 0;
        }

        .highlight-wp {
            margin: 20px 0;
            padding: 20px;
        }
    }
}
@include media-breakpoint-down(xs) {
    body .wysiwyg-wp > *:first-child {
        margin-top: 0 !important;
    }

    body .wysiwyg-wp > *:last-child {
        margin-bottom: 0 !important;
    }
}

#modal-content {
    .wysiwyg-wp h2 {
        font-size: 20px;
    }

    .wysiwyg-wp h3 {
        font-size: 17px;
    }

    .wysiwyg-wp h2,
    .wysiwyg-wp h3,
    .wysiwyg-wp ol,
    .wysiwyg-wp ul {
        margin: 20px 0;
    }

    .wysiwyg-wp p {
        font-size: 14px;
    }
    @include media-breakpoint-down(sm) {
        .wysiwyg-wp h2 {
            font-size: 18px;
        }

        .wysiwyg-wp h3 {
            font-size: 15px;
        }
    }
}
