// Header :: Fixed or small/large

body {
	padding-top: 0;
}

#main {
	position: relative;
}

.anchor-scroll {
	display: block;
	margin-top: 0;
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: transparent;
}
@include media-breakpoint-down(lg) {
	body {
		padding-top: 0;
	}

	.anchor-scroll {
		margin-top: 0;
	}
}
// Header :: Small on scroll

body.header-small #header {}
@include media-breakpoint-up(lg) {
	body.header-small #header {}
}
// Navigation :: Force Display on large device
@include media-breakpoint-up(sm) {
	#nav-main {
		display: flex !important;
	}

	#nav-mobile-trigger {
		display: none;
	}
}
// Header :: Structure

#nav-mobile-trigger {
	position: absolute;
	right: 15px;
	top: 25px;
}

#header {
	position: relative;
	z-index: 10;
	width: 100%;
	padding: 12.5px 0;
	background-color: $wh-a;
	transition: all .2s ease-in-out;
	@include media-breakpoint-down(sm) {
		margin-bottom: 10px;
		padding: 10px 0 0;
		.container {
			max-width: 100%;
			div[class^="col"] {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	@include media-breakpoint-down(xs) {
		margin-bottom: 0;
	}


	.logo {
		display: flex;
		align-items: center;
		.logo-img {
			width: 70px;
		}
		.logo-txt {
			color: $gy-b;
			font-weight: 500;
			font-size: 18px;
			padding-left: 15px;
			line-height: 1.2em;
			@include media-breakpoint-down(md) {
				font-size: 15px;
			}
		}
		@include media-breakpoint-down(sm) {
			justify-content: center;
			border-bottom: 1px solid $gy-a;
			padding-bottom: 10px;
			.logo-img {
				width: 50px;
			}
			.logo-txt {
				font-size: 14px;
			}
		}
		@include media-breakpoint-down(xs) {
			justify-content: flex-start;
			padding-left: 15px;
			padding-bottom: 0;
			border: 0;
			margin-bottom: 10px;
		}
	}
}
// Navigation :: Main

#nav-main {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	@include media-breakpoint-down(xs) {
		display: none;
		background-color: $gy-a;
	}

	.item {
		padding-left: 50px;
		//flex-grow: 1;
		&.except {
			.link {
				background-color: $rd-b;
				display: inline-block;
				line-height: 1em;
				padding: 11px 17px;
				line-height: 1em;
				border-radius: 5px;
				color: $wh-a;
				border: 2px solid $rd-b;
				transition: all .2s ease-in-out;
				&:hover {
					background-color: $wh-a;
					color: $rd-b;
				}
			}
		}
		&.focus {
			.link {
				color: $rd-b;
			}
		}
	}

	.link {
		display: block;
		color: $bk-a;
		text-align: center;
		font-size: 16px;
		font-weight: 500;
		padding: 5px;
		transition: all .2s ease-in-out;

		&.active,
		&:hover {
			color: $rd-b;
		}
	}

	.link:focus,
	.link:hover {}

	.link.active {}

	.nav-sub {
		.item-sub {
			&:not(:first-child) {
				margin-top: 10px;
			}
		}

		.link-sub {
			color: $wh-a;
			font-size: 15px;
			display: block;
			line-height: 1.1em;
			position: relative;
			padding-left: 10px;
			transition: all .2s ease-in-out;

			&:before {
				content: '•';
				position: absolute;
				font-size: 16px;
				top: 0;
				left: 0;
			}
			&:hover {
				padding-left: 15px;
			}
		}

		.link-sub:focus,
		.link-sub:hover {}

		.link-sub.active {}
	}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {
		.item {
			padding-left: 20px;
		}
		.link {
			font-size: 13px;
		}
	}
	@include media-breakpoint-down(sm) {
		justify-content: center;
		padding-top: 10px;
		.item.except {
			.link {
				padding: 8px 14px;
			}
		}
	}
	@include media-breakpoint-down(xs) {
		.item {
			padding: 0;
		}
		.item.except {
			text-align: center;
			padding: 5px 0 20px;
		}
	}
}
// Navigation :: Sub

.nav-sub {
	display: none;
	position: relative;
	top: auto;
	left: auto;
	height: auto;
	opacity: 1;
	background-color: $rd-b;
    padding: 10px;
}

li[data-navsub] {
	position: relative;
}

li[data-navsub].focus > .nav-sub {
	left: -25px;
}
@include media-breakpoint-down(md) {
	li[data-navsub].focus > .nav-sub {
		left: -55px;
	}
}
@include media-breakpoint-up(sm) {
	li[data-navsub].focus > .nav-sub {
		visibility: visible;
		display: block;
		top: 40px;
		padding: 20px 15px;
		height: auto;
		opacity: 1;
	}

	.nav-sub {
		display: none;
		position: absolute;
		left: 0;
		z-index: 99;
		height: 0;
		width: 210px;
		padding: 0;
		opacity: 0;
		background-color: $rd-b;
		transition: all .1 ease-in-out;


		&:before {
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 8px 10px;
			border-color: transparent transparent #fc0000 transparent;
			position: absolute;
			top: -8px;
			left: 50%;
			margin-left: -10px;
		}
	}
}

.header-page {
	background: $rd-b;
	color: $wh-a;
	text-align: center;
	padding: 35px 0;
	position: relative;

	&.bg-img {
		background-image: url('../images/illustrations/home-top.jpg');
		background-size: cover;
		background-position: center;
		padding: 70px 0;
		&:after {
			content:"";
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.3);
			position: absolute;
			top: 0;
			left: 0;
		}
		.title {
			position: relative;
			z-index: 2;
		}
		@include media-breakpoint-down(md) {
			padding: 50px 0;
		}
		@include media-breakpoint-down(sm) {
			padding: 25px 0;
		}
	}

	.title {
		font-size: 38px;
		font-weight: 500;
		margin-bottom: 0;
		em {
			font-weight: 300;
		}
	}
	@include media-breakpoint-down(md) {
		padding: 25px 0;
		.title {
			font-size: 34px;
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 20px 0;
		.title {
			font-size: 30px;
		}
	}
	@include media-breakpoint-down(xs) {
		.title {
			font-size: 26px;
		}
	}
}


.deco-inner-1,
.deco-inner-2 {
	position: absolute;
	z-index: 3;
}

.deco-inner-1 {
	left: 0;
	top: 350px;
}

.deco-inner-2 {
	right: 0;
	bottom: 100px;
}

@include media-breakpoint-down(md) {
	.deco-inner-1,
	.deco-inner-2 {
		display: none;
	}
}
