// Body
body {
	overflow: hidden;
	overflow-y: auto;
}

body,
html {
	width: 100%;
	background-color: #fff;
}
html {
	overflow-x: hidden;
}
// Utils :: Image renderer optimize

.renderer-optmimize {
	image-rendering: -moz-crisp-edges;
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	-ms-interpolation-mode: nearest-neighbor;
}
// Utils :: Hide play button on video iphone

*::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}
// Components :: Magnific popup

.mfp-close {
	width: 30px !important;
	height: 30px;
	line-height: 30px;
	position: absolute;
	right: 0;
	top: 5px;
	text-decoration: none;
	text-align: center;
	opacity: .65;
	filter: alpha(opacity=65);
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
	display: block;
	background-image: url('../images/icons/sprite-popup.png') !important;
	background-position: -60px 0 !important;
	text-indent: 999em;
	overflow: hidden!important;
	transition: all .2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow {
	position: absolute;
	opacity: .65;
	filter: alpha(opacity=65);
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 30px;
	height: 60px;
	display: block;
	background-image: url('../images/icons/sprite-popup.png') !important;
	transition: all .2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow-left {
	left: 20px;
	background-position: 0 0 !important;
}

.mfp-arrow-right {
	right: 20px;
	background-position: -30px 0 !important;
}

.mfp-arrow:after,
.mfp-arrow:before {
	display: none !important;
}

.mfp-zoom-out .mfp-with-anim {
	opacity: 0;
	transform: scale(1.3);
	transition: all .2s ease-in-out;
}

.mfp-zoom-out.mfp-bg {
	opacity: 0;
	transition: all .2s ease-in-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
	opacity: 1;
	transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
	opacity: .8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
	transform: scale(1.3);
	opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
	opacity: 0;
}
// Components :: Slick

.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: px(15);
	text-align: center;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}

	button {
		width: 10px;
		height: 10px;
		margin-right: 5px;
		background-color: $gy-b;
		border-radius: px(18);
		transition: all .2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active button {
		background-color: $rd-b;
	}

	button:focus,
	button:hover {
		background-color: $rd-b;
	}
}
// Components :: WOW

.fadeIn,
.fadeInDown,
.fadeInLeft,
.fadeInRight,
.fadeInUp {
	visibility: hidden;
}
// Function :: Equal Col
@include media-breakpoint-down(md) {
	.equal-col-item {
		min-height: 0 !important;
	}
}
// Component :: Google map

.map-wrapper {
	position: relative;
	height: 400px;
	background-color: #e5e3df;
	@include media-breakpoint-down(md) {
		height: 300px;
	}

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
// Component :: Parallax

.parallax-mirror {
	z-index: 0 !important;
}

.parallax-wp {
	@extend .clearfix;
	overflow: hidden;
	position: relative;
	height: 300px;
	background: transparent;
}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(xs) {}
// Slick :: Home

#slick-wp {}

#slick {}

#slick-home-wp {
	.item {}

	#slick-arrows {
		.arrow-next,
		.arrow-prev {}

		.arrow-prev {}

		.arrow-next {}
	}

	.slick-dots {}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(sm) {}
	// Animation classes
	.slick-slide .anim-content {
		opacity: 0;
		transform: scale(0.6);
		transition: all .2s ease-in-out .5s;
	}
	.slick-slide.animation .anim-content {
		opacity: 1;
		transform: scale(1);
	}
}

.section-home-top {
	background-color: $rd-b;
	color: $wh-a;
	padding: 60px 0;
	position: relative;
	overflow: hidden;

	.background {
		height: 100%;
		width: 55%;
		position: absolute;
		top: 0;
		right: 0;
		background-image: url('../images/illustrations/home-top.jpg');
		background-size: cover;
		background-position: center center;
		&:before {
			content: '';
			width: 50px;
			height: 130px;
			background-color: $rd-b;
			position: absolute;
			top: 0;
			right: calc(100% - 40px);
		}
	}
	@include media-breakpoint-down(lg) {
		.background {
			width: 45%;
		}
	}
	@include media-breakpoint-down(md) {
		padding-top: 20px;
		padding-bottom: 0;
		.background {
			margin-top: 20px;
			width: 100%;
			height: 200px;
			background-position: top center;
			position: relative;
			top: auto;
			right: auto;
			&:before {
				display: none;
			}
		}
	}
}

.activites-except {
	position: relative;
	&:after {
		content: '';
		width: 300%;
		background-color: $gy-a;
		height: calc(100% + 80px);
		position: absolute;
		top: -40px;
		right: calc(-300% + 700px);
		z-index: 1;
		@include media-breakpoint-down(md) {
			display: none;
		}

	}
	div[class^="col"] {
		position: relative;
		z-index: 2;
	}
	figure {
		&:after {
			content: '';
			background-color: $gn-a;
			position: absolute;
			top: 20px;
			left: -15px;
			width: 430px;
			height: 370px;
			@include media-breakpoint-down(lg) {
				width: 355px;
				height: 305px;
			}
			@include media-breakpoint-down(md) {
				width: 340px;
				height: 290px;
			}
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
		img {
			position: relative;
			z-index: 1;
		}
	}
}

.section-home-missions {
	padding: 60px 0;
	position: relative;
	overflow: hidden;
	height: 450px;
	.background {
		height: 100%;
		width: 50%;
		position: absolute;
		top: 0;
		right: 0;
		background-image: url('../images/illustrations/home-missions.jpg');
		background-size: cover;
		background-position: top left;
	}
	@include media-breakpoint-down(lg) {
		height: 400px;
		.background {
			width: 45%;
		}
	}
	@include media-breakpoint-down(md) {
		padding-top: 20px;
		padding-bottom: 0;
		height: auto;
		.background {
			margin-top: 20px;
			width: 100%;
			height: 300px;
			background-position: top center;
			position: relative;
			top: auto;
			right: auto;
			&:before {
				display: none;
			}
		}
	}
}

.section-home-numbers {
	padding: 60px 0;
	position: relative;
	overflow: hidden;
	background-color: $bk-a;
	color: $wh-a;
	&:after {
		content: '';
		width: 300px;
		height: 5px;
		background-color: $rd-b;
		position: absolute;
		bottom: 0;
		left: 50%;
	}

	.number {
		font-size: 26px;
		font-weight: 500;
		font-style: italic;
	}
	.background {
		height: 100%;
		width: 50%;
		position: absolute;
		top: 0;
		left: 0;
		background-image: url('../images/illustrations/home-presentation.jpg');
		background-size: cover;
		background-position: center;
		&.except {
			background-image: url('../images/illustrations/home-missions.jpg');
		}
	}
	@include media-breakpoint-down(lg) {
		&:after {
			left: 55%;
		}
		.background {
			width: 55%;
		}
	}
	@include media-breakpoint-down(md) {
		&:after {
			left: 45%;
		}
		.background {
			width: 45%;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: 0;
		padding-bottom: 20px;
		.background {
			width: 100%;
			height: 300px;
			background-position: top center;
			position: relative;
			top: auto;
			right: auto;
			margin-bottom: 20px;
		}
		&:after {
			display: none;
		}
	}

}

.deco-home-1,
.deco-home-2,
.deco-home-3 {
	position: absolute;
}

.deco-home-1 {
	right: 0;
	top: 1900px;
}
.deco-home-2 {
	left: 0;
	top: 3050px;
}
.deco-home-3 {
	left: 0;
	top: 600px;
}

@include media-breakpoint-down(md) {
	.deco-home-3 {
		top: 675px;
	}
	.deco-home-1 {
		top: 2700px;
	}
	.deco-home-2 {
		display: none;
	}
}

@include media-breakpoint-down(sm) {
	.deco-home-1,
	.deco-home-2,
	.deco-home-3 {
		display: none;
	}
}

.home-section-job {
	background-color: $gy-a;
	position: relative;
	min-height: 250px;
	.title {
		&:before {
			display: block;
			content: '';
			width: 60px;
			height: 6px;
			margin-bottom: 20px;
			background-color: $rd-b;
		}
		font-size: 32px;
		line-height: 1.1em;
		font-weight: 500;
	}
	.img-fluid {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 331px;
	}
	@include media-breakpoint-down(md) {
		.title {
			font-size: 28px;
		}
	}

	@include media-breakpoint-down(sm) {
		min-height: auto;
		.title {
			margin-top: 5px;
			font-size: 26px;
		}
		.img-fluid {
			display: none;
		}
	}
}

#slick-home-wp {
	color: $wh-a;
	.col-img {
		top: 0;
		left: 0;
		position: absolute;
		width: calc(100% - 75px);
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.btn-wp {
		position: absolute;
		left: calc(50% - 50px);
		z-index: 10;
		background-color: $wh-a;
		font-weight: 500;
		font-size: 13px;
		color: $bk-a;
		height: 50px;
		width: 250px;
		text-align: center;
		padding-top: 12.5px;
		bottom: 0;
	}

	.background {
		height: calc(100% - 5px);
		width: 200%;
		position: absolute;
		top: 0;
		left: calc(50% - 50px);
		background-color: $rd-b;
		@include media-breakpoint-down(md) {
			height: 100%;
		}
	}

	#slick-home-arrows {
		position: absolute;
		bottom: 15px;
		left: calc(50% + 230px);
		z-index: 2;
		> * {
			&:first-child {
				margin-right: 50px;
			}
		}
	}
	.content {
		background-color: $rd-b;
		padding-top: 60px;
		padding-bottom: 80px;
        min-height: 400px;
	}
	.category {
		font-weight: 300;
		opacity: .9;
	}
	.text {
		font-weight: 500;
	}
	@include media-breakpoint-down(sm) {
		.content {
			padding: 20px 25px;
		}
		#slick-home-arrows {
			background-color: $rd-b;
			left: auto;
			right: 15px;
			bottom: -15px;
			padding: 5px 10px;
			> * {
				&:first-child {
					margin-right: 10px;
				}
			}
		}
		.btn-wp {
			position: relative;
			bottom: -10px;
			left: auto;
			padding: 0;
		}
		.col-img {
			position: relative;
			top: auto;
			left: auto;
			height: 300px;
			width: 100%;
		}
		.background {
			display: none;
		}
	}
}

.section-slick-home {
	position: relative;
	.deco-home {
		position: absolute;
		left: 0;
		top: 70px;
		@include media-breakpoint-down(md) {
			top: 50px;
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
}

.realisations-item {
	height: 100%;
	background-color: $rd-b;
	position: relative;
	&.small {
		.title {
			font-size: 17px;
		}
	}
	.img-wp {
		overflow: hidden;
		img {
			transition: all .2s ease-in-out;
		}
	}

	.col-bottom,
	.header {
		padding-left: 20px;
		padding-right: 20px;
	}
	.header {
		padding-top: 20px;
	}
	.col-bottom {
		padding-top: 10px;
		padding-bottom: 20px;
	}

	.title {
		font-size: 20px;
		line-height: 1.4em;
		font-weight: 500;
		color: $wh-a;
		font-style: italic;
	}

	.link {
		height: 100%;
		justify-content: space-between;
		display: flex;
		flex-flow: column nowrap;
		&:hover {
			img {
				transform: scale(1.1);
			}
			.btn-a {
				background-color: $wh-a;
				color: $bk-a;
				border-color: $wh-a;
			}
		}
	}
}

.realisation-article-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $rd-b;
	padding: 0 10px;
	border-radius: 5px;
	background-color: $wh-a;
	width: 80px;
	height: 80px;
	border-radius: 100%;
	position: relative;
	z-index: 2;
}

.realisation-article-job {
	position: relative;
	z-index: 2;
	font-size: 16px;
	text-decoration: underline;
	font-weight: 500;
}

.realisation-article-number {
	text-align: center;
	.number-wp {
		justify-content: center;
		display: flex;
		font-size: 50px;
		font-weight: 500;
		color: $rd-b;
		align-items: center;
		.unit {
			padding-left: 5px;
			font-size: 70%;
		}
	}
	.number-title {
		font-size: 15px;
		line-height: 1.3em;
		font-style: italic;
	}
	@include media-breakpoint-down(sm) {
		.number-wp {
			font-size: 40px;
		}
		.number-title {
			font-size: 14px;
		}
	}
}

.job-list-item {
	display: block;
	text-align: center;
	.title {
		margin-top: 10px;
		font-size: 18px;
		line-height: 1.2em;
	}
	> * {
		margin: 0 auto;
		display: block;
	}
}

.job-list-team-item {
	background-color: $gy-a;
	height: 100%;
	.content {
		font-size: 18px;
		font-style: italic;
		line-height: 1.2em;
		font-weight: 500;
		padding: 20px 15px;
	}
}
